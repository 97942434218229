import { CompletedRow } from './CompletedRow'
import { CurrentRow } from './CurrentRow'
import { EmptyRow } from './EmptyRow'

type Props = {
  guesses: string[]
  currentGuess: string,
  username: string | null,
  guessUsers: string[]
}

export const Grid = ({ guesses, currentGuess, username, guessUsers }: Props) => {
  const empties =
    guesses.length < 5 ? Array.from(Array(5 - guesses.length)) : []

  //let currentGuessIndex = guesses.find( (element, index) => { if (element == currentGuess) {return Number(index)} } )
  let currentGuessIndex = guesses.findIndex(el => el === currentGuess)
  let currentGuessUser = currentGuessIndex >= 0 ? guessUsers[currentGuessIndex] : ''

  return (
    <div className="pb-6">
      {guesses.map((guess, i) => {
          let guessUser = guessUsers ? guessUsers[i] : ''
            return (<CompletedRow key={i} guess={guess} guessUser={guessUser} />)
      })}
      {guesses.length < 6 && <CurrentRow guess={currentGuess} username={username} guessUser={currentGuessUser} />}
      {empties.map((_, i) => (
        <EmptyRow key={i} />
      ))}
    </div>
  )
}
