import {Socket} from "socket.io-client";

const gameStateKey = 'gameState'

type StoredGameState = {
    guesses: string[]
    solution: string
}

export const saveGameStateToCsStorage = (gameState: StoredGameState) => {
    localStorage.setItem(gameStateKey, JSON.stringify(gameState))
}

export const loadGameStateFromCsStorage = (gameId:any) => {
    const state = localStorage.getItem(gameStateKey)
    return state ? (JSON.parse(state) as StoredGameState) : null
}

//const gameStatKey = 'gameStats'

export type GameStats = {
    winDistribution: number[]
    gamesFailed: number
    currentStreak: number
    bestStreak: number
    totalGames: number
    successRate: number
}

export const saveStatsToCsStorage = (cs:Socket, gameId:any, gameStats: GameStats) => {

    cs.emit('saveStats', {gameId : gameId, stats: gameStats})

    //localStorage.setItem(gameStatKey, JSON.stringify(gameStats))
}

export const loadStatsFromCsStorage = (cs:Socket, gameId: any, updateStats:any) => {

    cs.emit('loadStats', {gameId: gameId}, (response:any) => {
        console.log('loadStatsFromCsStorage', response.stats)
        //if (response.stats.length) {
            updateStats(response.stats)
        //}
    })

    //return stats ? (JSON.parse(stats) as GameStats) : null
}
