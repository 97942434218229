import {
  InformationCircleIcon,
  ChartBarIcon,
  SunIcon,
} from '@heroicons/react/outline'
import { useState, useEffect } from 'react'
import { Alert } from './components/alerts/Alert'
import { Grid } from './components/grid/Grid'
import { Keyboard } from './components/keyboard/Keyboard'
import { AboutModal } from './components/modals/AboutModal'
import { InfoModal } from './components/modals/InfoModal'
import { StatsModal } from './components/modals/StatsModal'
import {
  GAME_TITLE,
  WIN_MESSAGES,
  GAME_COPIED_MESSAGE,
  ABOUT_GAME_MESSAGE,
  NOT_ENOUGH_LETTERS_MESSAGE,
  WORD_NOT_FOUND_MESSAGE,
  CORRECT_WORD_MESSAGE,
} from './constants/strings'
import { isWordInWordList, isWinningWord, solution } from './lib/words'
import { addStatsForCompletedGame, loadStats } from './lib/stats'
import {
  loadGameStateFromLocalStorage,
  saveGameStateToLocalStorage,
} from './lib/localStorage'

import './App.css'

import { browserName, browserVersion } from 'react-device-detect'
import socketIOClient from 'socket.io-client'
import { useParams } from 'react-router-dom'
import { UserModal } from './components/modals/UserModal'
var socket = socketIOClient(
  process.env.REACT_APP_CS_SERVER
    ? process.env.REACT_APP_CS_SERVER.toString()
    : ''
)

const ALERT_TIME_MS = 2000

function App() {
  const prefersDarkMode = window.matchMedia(
    '(prefers-color-scheme: dark)'
  ).matches

  let { gameId } = useParams()
  const [username, setUsername] = useState<string | null>(
    localStorage.getItem('username')
  )
  const [isUserModalOpen, setIsUserModalOpen] = useState(
    localStorage.getItem('username') ? false : true
  )
  const [guessUsers, setGuessUsers] = useState<string[]>([])
  const [isLoaded, setIsLoaded] = useState(false)
  const [currentGuess, setCurrentGuess] = useState('')
  const [isGameWon, setIsGameWon] = useState(false)
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false)
  const [isAboutModalOpen, setIsAboutModalOpen] = useState(false)
  const [isNotEnoughLetters, setIsNotEnoughLetters] = useState(false)
  const [isStatsModalOpen, setIsStatsModalOpen] = useState(false)
  const [isWordNotFoundAlertOpen, setIsWordNotFoundAlertOpen] = useState(false)
  const [isGameLost, setIsGameLost] = useState(false)
  const [isDarkMode, setIsDarkMode] = useState(
    localStorage.getItem('theme')
      ? localStorage.getItem('theme') === 'dark'
      : prefersDarkMode
      ? true
      : false
  )
  const [successAlert, setSuccessAlert] = useState('')
  const [guesses, setGuesses] = useState<string[]>(() => {
    const loaded = loadGameStateFromLocalStorage()
    if (loaded?.solution !== solution) {
      return []
    }
    const gameWasWon = loaded.guesses.includes(solution)
    if (gameWasWon) {
      setIsGameWon(true)
    }
    if (loaded.guesses.length === 6 && !gameWasWon) {
      setIsGameLost(true)
    }
    return loaded.guesses
  })

  const [stats, setStats] = useState(() =>
    loadStats(socket, gameId, loadStatsHandler)
  )

  function loadStatsHandler() {
    loadStats(socket, gameId, setStats)
  }

  useEffect(() => {
    if (socket) {
      if (!isLoaded) {
        socket.emit(
          'joinGame',
          {
            gameId: gameId,
            username: username ? username : browserName + ':' + browserVersion,
          },
          (resp: any) => {
            console.log('joinGame:guesses', resp.guesses)
            console.log('joinGame:users', resp.guessUsers)
            if (resp.guesses.length) {
              if (JSON.stringify(resp.guesses) !== JSON.stringify(guesses)) {
                setIsLoaded(true)
                setGuesses(JSON.parse(resp.guesses))
                setGuessUsers(JSON.parse(resp.guessUsers))
                setCurrentGuess('')
              }
            } else {
              setIsLoaded(true)
              setGuesses([])
              setCurrentGuess('')
              setIsGameLost(false)
              setIsGameWon(false)
              setIsStatsModalOpen(false)
            }
          }
        )
      } else {
        console.log('isLoaded')
      }

      socket.on('updateBoard', ({ gameId, word, csGuesses, guessUsers }) => {
        if (JSON.stringify(csGuesses) === JSON.stringify(guesses)) {
          //
        } else {
          setGuessUsers(guessUsers)
          setGuesses(csGuesses)
          setCurrentGuess('')
        }
      })

      // update board
    } else {
      console.log('NO Socket')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (isDarkMode) {
      document.documentElement.classList.add('dark')
    } else {
      document.documentElement.classList.remove('dark')
    }
  }, [isDarkMode])

  const handleDarkMode = (isDark: boolean) => {
    setIsDarkMode(isDark)
    localStorage.setItem('theme', isDark ? 'dark' : 'light')
  }

  useEffect(() => {
    saveGameStateToLocalStorage({ guesses, solution })
  }, [guesses])

  useEffect(() => {
    if (isGameWon) {
      setSuccessAlert(
        WIN_MESSAGES[Math.floor(Math.random() * WIN_MESSAGES.length)]
      )
      setTimeout(() => {
        setSuccessAlert('')
        setIsStatsModalOpen(true)
      }, ALERT_TIME_MS)
    }
    if (isGameLost) {
      setTimeout(() => {
        setIsStatsModalOpen(true)
      }, ALERT_TIME_MS)
    }
  }, [isGameWon, isGameLost])

  const onChar = (value: string) => {
    if (
      currentGuess.length < 5 &&
      guesses.length < 6 &&
      !isGameWon &&
      !isUserModalOpen
    ) {
      setCurrentGuess(`${currentGuess}${value}`)
    }
  }

  const onDelete = () => {
    setCurrentGuess(currentGuess.slice(0, -1))
  }

  const onEnter = () => {
    if (isUserModalOpen) {
      return
    }

    if (isGameWon || isGameLost) {
      return
    }
    if (!(currentGuess.length === 5)) {
      setIsNotEnoughLetters(true)
      return setTimeout(() => {
        setIsNotEnoughLetters(false)
      }, ALERT_TIME_MS)
    }

    if (!isWordInWordList(currentGuess)) {
      setIsWordNotFoundAlertOpen(true)
      return setTimeout(() => {
        setIsWordNotFoundAlertOpen(false)
      }, ALERT_TIME_MS)
    }

    if (socket) {
      setGuessUsers([...guessUsers, username!])
      socket.emit('syncWord', {
        gameId: gameId,
        word: currentGuess,
        guesses: guesses,
        username: username,
        guessUsers: guessUsers,
      })
    }

    const winningWord = isWinningWord(currentGuess)

    if (currentGuess.length === 5 && guesses.length < 6 && !isGameWon) {
      setGuesses([...guesses, currentGuess])
      setCurrentGuess('')

      if (winningWord) {
        setStats(
          addStatsForCompletedGame(socket, gameId, stats, guesses.length)
        )
        return setIsGameWon(true)
      }

      if (guesses.length === 5) {
        setStats(
          addStatsForCompletedGame(socket, gameId, stats, guesses.length + 1)
        )
        setIsGameLost(true)
      }
    }
  }

  return (
    <div className="py-8 max-w-7xl mx-auto sm:px-6 lg:px-8">
      <div className="flex w-80 mx-auto items-center mb-8 mt-12">
        <h1 className="text-xl grow font-bold dark:text-white">{GAME_TITLE}</h1>
        <h2
          className="text-xl grow dark:text-white"
          onClick={() => setIsUserModalOpen(!isUserModalOpen)}
        >
          {username}
        </h2>
        <SunIcon
          className="h-6 w-6 cursor-pointer dark:stroke-white"
          onClick={() => handleDarkMode(!isDarkMode)}
        />
        <InformationCircleIcon
          className="h-6 w-6 cursor-pointer dark:stroke-white"
          onClick={() => setIsInfoModalOpen(true)}
        />
        <ChartBarIcon
          className="h-6 w-6 cursor-pointer dark:stroke-white"
          onClick={() => setIsStatsModalOpen(true)}
        />
      </div>
      <Grid
        guesses={guesses}
        currentGuess={currentGuess}
        username={username}
        guessUsers={guessUsers}
      />
      <Keyboard
        onChar={onChar}
        onDelete={onDelete}
        onEnter={onEnter}
        guesses={guesses}
      />
      <InfoModal
        isOpen={isInfoModalOpen}
        handleClose={() => setIsInfoModalOpen(false)}
      />
      <StatsModal
        isOpen={isStatsModalOpen}
        handleClose={() => setIsStatsModalOpen(false)}
        guesses={guesses}
        gameStats={stats}
        isGameLost={isGameLost}
        isGameWon={isGameWon}
        handleShare={() => {
          setSuccessAlert(GAME_COPIED_MESSAGE)
          return setTimeout(() => setSuccessAlert(''), ALERT_TIME_MS)
        }}
      />
      <AboutModal
        isOpen={isAboutModalOpen}
        handleClose={() => setIsAboutModalOpen(false)}
      />

      <UserModal
        isOpen={isUserModalOpen}
        handleClose={() => setIsUserModalOpen(false)}
        handleSaveUser={(val) => {
          console.log('handleSaveUser=', val)
          if (val) {
            localStorage.setItem('username', val)
          }

          setUsername(val)
          setIsUserModalOpen(false)
        }}
        value={username}
      />

      <button
        type="button"
        className="mx-auto mt-8 flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 select-none"
        onClick={() => setIsAboutModalOpen(true)}
      >
        {ABOUT_GAME_MESSAGE}
      </button>

      <Alert message={NOT_ENOUGH_LETTERS_MESSAGE} isOpen={isNotEnoughLetters} />
      <Alert
        message={WORD_NOT_FOUND_MESSAGE}
        isOpen={isWordNotFoundAlertOpen}
      />
      <Alert message={CORRECT_WORD_MESSAGE(solution)} isOpen={isGameLost} />
      <Alert
        message={successAlert}
        isOpen={successAlert !== ''}
        variant="success"
      />
    </div>
  )
}

export default App
