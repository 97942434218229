import { Cell } from './Cell'

type Props = {
  guess: string,
  username: string | null,
  guessUser: string
}

export const CurrentRow = ({ guess, username, guessUser }: Props) => {
  const splitGuess = guess.split('')
  const emptyCells = Array.from(Array(5 - splitGuess.length))

  return (
        <div className="flex justify-center mb-1">
          {splitGuess.map((letter, i) => (
            <Cell key={i} value={letter} />
          ))}
          {emptyCells.map((_, i) => (
            <Cell key={i} />
          ))}
          <div className="lex ml-5 justify-center username mx-0.5 items-center rounded border-solid border-2
                        dark:text-white">{guessUser}</div>
        </div>

  )
}
