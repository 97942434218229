import { getGuessStatuses } from '../../lib/statuses'
import { Cell } from './Cell'

type Props = {
  guess: string,
  guessUser: string
}

export const CompletedRow = ({ guess, guessUser }: Props) => {
  const statuses = getGuessStatuses(guess)

  return (
      <div className="flex justify-center mb-1" >
        {guess.split('').map((letter, i) => (
          <Cell key={i} value={letter} status={statuses[i]} />
        ))}
        <div className="flex ml-5 justify-center username mx-0.5 items-center rounded border-solid border-2
                        dark:text-white">
          {guessUser}
        </div>
      </div>
  )
}
