import { Cell } from './Cell'

export const EmptyRow = () => {
  const emptyCells = Array.from(Array(5))

  return (
    <div className="flex justify-center mb-1">
      {emptyCells.map((_, i) => (
        <Cell key={i} />
      ))}
      <div className="lex ml-5 justify-center username mx-0.5 items-center rounded border-solid border-2
                        dark:text-white" ></div>
    </div>

  )
}
