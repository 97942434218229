import { BaseModal } from './BaseModal'
import React, {useEffect} from "react";

type Props = {
    value: string | null
    isOpen: boolean
    handleClose: () => void
    handleSaveUser: (val: string) => void
}

export const UserModal = ({ value, isOpen, handleClose, handleSaveUser }: Props) => {

    let usernameRef = React.useRef<HTMLInputElement>(null)

    useEffect(() => {
        if (usernameRef && usernameRef.current) usernameRef.current.focus()
    })

    const defValue = value === null ? '' : value

    return (
        <BaseModal title="Username" isOpen={isOpen} handleClose={handleClose}>

            <div className="flex justify-center mb-1 mt-4 input-text border-2 width-100">
                <input name="username" id="username" className="form-input" ref={usernameRef} defaultValue={defValue} />
            </div>
            <button className="mx-auto mt-8 flex items-center px-2.5 py-1.5 border border-transparent font-medium rounded  bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 select-none dark:text-white"
                    onClick={() => {
                        let val = '';
                        if ( usernameRef.current ) {
                            val = usernameRef.current?.value
                        }
                        handleSaveUser(val)
            }}>Save</button>

        </BaseModal>
    )
}
